/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';

/* You can add global styles to this file, and also import other style files */
.modal-holder {
  animation-name: example;
  animation-duration: 0.3s;
}

@keyframes example {
  0% {
    transform: scale(0.5)
  }

  100% {
    transform: scale(1)
  }
}

.carousel-item {
  transition: opacity 0.7s ease !important;
  position: absolute !important;
  display: block !important;
  opacity: 0;
}

.carousel-item.active {
  position: relative !important;
  opacity: 1;
}
#toast-container > div {
  opacity:1;
}


.assign-coach-drop{
  width: 75%;
}

.section-block {
  border: 1px solid #ccc;
  padding: 20px;
  margin-bottom: 20px;
  background-color: #f9f9f9;
  border-radius: 5px;
  margin-top: 10px;
}

.section-title {
  color: #0056b3;
  font-weight: 500;
}

.btn-danger {
  background-color: #dc3545 !important;
  border-color: #dc3545;
  transition: background-color 0.3s, border-color 0.3s;
  box-shadow: 1px 1px 3px rgba(0,0,0,0.15);
}

.btn-danger:hover {
  background-color: #bd2130 !important;
  border-color: #bd2130;
}

.highlighted-block {
  padding: 20px;
  margin: 20px 0;
  border-radius: 4px;
  border: 1px solid #e0e0e0 !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1) !important;
}

.btn-success {
  background: #ffffff !important;
  border: #0056b3 2px solid !important;
  color: #0056b3 !important;
  font-weight: 500 !important;
  box-shadow: none !important;
}

.btn-success:hover,
.btn-success:focus-visible {
  background: #0056b3 !important;
  color: #ffffff !important;
}

.primary-text {
  color: #0056b3;
}

.ngx-pagination .current {
  background: #0056b3 !important;
  border-radius: 2px !important;
}

.error-message {
  font-size: 80%;
  color: #f46a6a;
  margin-top: 5px;
}

.error-input {
  border: 1px solid red !important;
}

.swal2-cancel {
  background: #ffffff !important;
  border: #0056b3 2px solid !important;
  color: #0056b3 !important;
}


.swal2-confirm {
  background: #0056b3 !important;
  border: #ffffff 2px solid !important;
}


.swal2-confirm:focus {
  box-shadow: none !important;
}

.btn-primary {
  background: #0056b3 !important;
  color: #ffffff !important;
}


.btn-primary:hover {
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.3);
}


.btn-outline-danger {
  border: #dc3545 2px solid !important;
  color: #dc3545 !important;
}


.btn-outline-danger:hover {
  background-color: #bd2130 !important;
  border-color: #bd2130;
  color: #ffffff !important;
}

.login-input {
  padding: .5rem 1.25rem !important;
  border: 2px solid transparent;
  outline: none;
  border-radius: 1.5rem !important;
  background-color: rgba(255, 255, 255, 0.25);
  box-shadow: 0 0 5pt 0.5pt #D3D3D3;
}

#password-addon {
  border-radius: 1.5rem;
  background-color: transparent !important;
  position: absolute !important;
  right: 0;
  top: 2px;
  z-index: 5 !important;
  outline: none !important;
  border: none;
}

#password-addon:focus {
  box-shadow: none !important;
}

.primary-color {
  color: #2F80ED;
}

.language-block {
  position: absolute;
  right: 15px;
  top: 0;
}

.back-ground-image1,
.back-ground-image2,
.back-ground-image3 {
  position: absolute;
}

.back-ground-image1 {
  width: 60%;
  top: 0%;
  right: 0%;
}

.back-ground-image2 {
  width: 18%;
  top: 0%;
  left: 0%;
}

.back-ground-image3 {
  width: 9%;
  bottom: 5%;
  left: 0%;
}

.back-ground-image1 img,
.back-ground-image2 img,
.back-ground-image3 img {
  width: 100%;
  height: 100%;
}

body {
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  color: var(--sp-text-black) !important;
  overflow-x: hidden;
}

.coach-image {
  flex: none;
  order: 1;
  flex-grow: 0;
  border-radius: 50%;
  width: 130px;
  height: 130px;
  display: flex;
  justify-content: center;
  margin: 0 auto;
}

.coach-image img {
  object-fit: cover;
  width: 100%;
}
